import React, { createRef } from "react"
import Bg from "../../images/bg.png"
import { BackBtn } from "../../components/icons"
import PropertyCard from "../../components/propertyCard"
import theMeadows from "../../assets/propertyMart/meadows/gallery/the meadows 1.jpg"
import PropertyMartLogo from "../../assets/propertyMart/PropertymartLogo.png" 
import { SampleNextArrow, SamplePrevArrow } from "../../components/arrowButtons"

const Property = () => {
  const customeSlider = createRef();
  const gotoNext = () => {
    customeSlider.current.slickNext()
  }

  const gotoPrev = () => {
    customeSlider.current.slickPrev()
  }
  const data = [
    {
      id: 1,
      title: "ThE MEADOWS",
      imgUrl: theMeadows,
      link: "property-mart/the-meadows",
      text:
        "Meadows is a Smart Green Community designed with the concept of luxury, surrounded with nature",
    },
  ]

  const settings = {
    dots: true,
    dotsClass: "line-dots property",
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <>
          <div data-dots={i + 1} className="dots">
            &nbsp;
          </div>
        </>
      )
    },
  }



  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex flex-col w-full">
        <div className="relative md:flex md:justify-center md:items-stretch md:w-full">

          <div
            className="relative min-h-full md:min-h-screen py-12 px-6 md:p-12 md:w-full md:flex md:items-center md:justify-center"
            style={{
              backgroundImage: `url(${Bg} )`,
            }}
          >
            <div className="relative md:absolute top-0 left-0 right-0 md:pr-6 md:pl-6 md:mr-16 md:ml-16 md:mt-12 flex-col md:flex-row flex justify-between md:items-center">
              <div className="w-1/3">
                <BackBtn
                  color={"#982A7D"}
                  height={"20px"}
                  onClick={() => window.history.back()}
                />
              </div>
              <div className="md:w-1/3 my-4 md:my-0 mx-auto">
                <img src={PropertyMartLogo} className="h-16 mx-auto m-0" />
              </div>
              <div className="md:w-1/3 text-right mx-auto">
              </div>
            </div>
            {/* <div>
              <SamplePrevArrow onClick={gotoPrev} />
              <Slider
                {...settings}
                ref={customeSlider}
                className="overflow-hidden py-12 mt-24 property-slide"
              >
                {data.map((el, key) => (
                  <PropertyCard key={key} {...el} />
                ))}
              </Slider>
              <SampleNextArrow onClick={gotoNext} />
            </div> */}

            <section className="flex justify-center items-center mt-24 space-x-12">
              {data.map((el, key) => (
                <PropertyCard key={key} {...el} />
              ))}
            </section>

          </div>
        </div>
      </div>
    </div>
  )
}
export default Property
